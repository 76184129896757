import classNames from "classnames";
import { Card } from "components/card";
import { Container } from "components/container";
import { Link } from "components/link";
import { Shape } from "components/shape";
import { getEdgeSettings } from "components/wrapper/components";
import { Media, WrapperComponentProps } from "constants/types";
import { getSpeakingId } from "helpers/text-processing";
import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { colors } from "theme/theme";
import { Ct, Im, Vi } from "types";
import HeadingWrapper from "../heading";

interface ContactWrapperProps extends WrapperComponentProps {
  contact_entity?: Ct[];
  hide_shadow: boolean;
  key_visual: Im[] | Vi[];
  show_button: boolean;
  show_company: boolean;
  show_job_title: boolean;
  show_key_visual: boolean;
  show_mobile: boolean;
  use_padding: boolean;
}

const ContactWrapper: FC<ContactWrapperProps> = (props) => {
  const entity = props?.contact_entity?.[0];

  const title = entity?.title;

  const streetAddress = entity?.street_address?.replace(/\n/g, "<br />");
  const openingHours = entity?.opening_hours;
  const keyVisual =
    props.show_key_visual && (props?.key_visual || entity?.key_visual);

  const jobTitle =
    !!props.show_job_title && (entity?.job_title || entity?.job_title_2);
  const company = !!props.show_company && entity.ct_company;

  const phone = entity?.phone_1;
  const mobile = props.show_mobile && entity?.mobile;
  const fax = entity?.fax;
  const email = entity?.email;
  const website = entity?.website;

  const showContactButtons = !!props.show_button;

  const excerpt =
    entity?.subtitle ||
    (!streetAddress && !phone ? entity?.excerpt : streetAddress);

  return (
    <Container
      edge={getEdgeSettings(props)}
      marginTop={props.margin_top}
      marginBottom={props.margin_bottom}
      background={props.background_color}
      layout={props.header_layout}
      id={`component__contact-${props.index}`}
      className="section component__contact"
    >
      <HeadingWrapper {...props} />
      <Row id={getSpeakingId(entity)}>
        <Col
          md={
            typeof props.parent === "object" &&
            Object.keys(props.parent).length === 0
              ? 4
              : 12
          }
        >
          <Card
            variant={keyVisual?.[0] ? "horizontal-outline" : "vertical-outline"}
            title={title}
            html={excerpt}
            {...(keyVisual?.[0] && {
              cardImg: {
                media: keyVisual?.[0] as Media,
                border: true,
                width: 440,
                height: 330,
                ar: "ar11",
              },
            })}
            className={classNames(
              !props.title && "no-title",
              props.use_padding && "use-padding",
              props.hide_shadow && "hide-shadow",
            )}
          >
            <>
              {(!!jobTitle || !!company) && (
                <div style={{ marginTop: "10px" }}>
                  {!!jobTitle && <div>{jobTitle}</div>}
                  {!!company && <div>{company}</div>}
                </div>
              )}
              {((showContactButtons &&
                (phone || mobile || fax || email || website)) ||
                openingHours) && (
                <div style={{ marginTop: "15px" }}>
                  {showContactButtons && (
                    <>
                      {!!phone && (
                        <p>
                          <Link
                            href={`tel:${phone.replace(/\s/g, "")}`}
                            label={phone}
                            icon={{ variant: "phone", fill: colors.blue }}
                            isAnchor
                          />
                        </p>
                      )}
                      {!!mobile && (
                        <p>
                          <Link
                            href={`tel:${mobile.replace(/\s/g, "")}`}
                            label={mobile}
                            icon={{ variant: "phone", fill: colors.blue }}
                            isAnchor
                          />
                        </p>
                      )}
                      {!!fax && (
                        <p>
                          <Link
                            href={`fax:${fax.replace(/\s/g, "")}`}
                            label={fax}
                            icon={{ variant: "print", fill: colors.blue }}
                            isAnchor
                          />
                        </p>
                      )}
                      {!!email && (
                        <p>
                          <Link
                            href={`mailto:${email}`}
                            label={email}
                            icon={{ variant: "envelope", fill: colors.blue }}
                            isAnchor
                          />
                        </p>
                      )}
                      {!!website && (
                        <p>
                          <Link
                            href={website}
                            label={website}
                            icon={{ variant: "globe", fill: colors.blue }}
                            isAnchor
                          />
                        </p>
                      )}
                    </>
                  )}
                  {!!openingHours && (
                    <p className="contact-item flex-start-center">
                      <Shape variant="display" fill={colors.gray100} />
                      <div className="label">{openingHours}</div>
                    </p>
                  )}
                </div>
              )}
            </>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactWrapper;
